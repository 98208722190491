
/**
 * User Role view and creation.
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { Component, Mixins } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { required, alphaNum } from "vuelidate/lib/validators";

import TableHeader from "@/components/tableHeader.vue";
import { AxiosResponse } from "axios";
import { Role } from "@/interface/RoleInterface";
import confirmModal from "@/mixins/confirmModal/confirmModal";
import UserAccess from "@/model/User/UserAccess";

@Component({
  name: "UserRoleList",
  components: {
    TableHeader
  },
  computed: {
    ...mapGetters(["getRoleList", "getUserAccess"])
  },
  methods: {
    ...mapActions(["getAllRole", "addRoleToList", "deleteRole"])
  }
})
export default class UserRoleList extends Mixins(confirmModal) {
  /*----------  Vuex  ----------*/
  readonly getRoleList!: Array<Role>;
  readonly getUserAccess!: UserAccess;

  private getAllRole!: Function;
  private addRoleToList!: Function;
  private deleteRole!: Function;

  /*----------  Local data  ----------*/
  fields: Array<string> = ["name", "actions"];
  error: null | { class: string; msg: string } = null;
  role: Role = {
    name: ""
  };
  loading = true;

  /*----------  Form validation  ----------*/
  validations() {
    return {
      role: {
        name: {
          required,
          alphaNum
        }
      }
    };
  }

  /*----------  Vuejs lifecycles  ----------*/
  created() {
    this.loading = true;
    this.getAllRole().then(() => {
      this.loading = false;
    });
  }

  /*----------  Compuited  ----------*/
  private get removeSpaces(): boolean {
    var regex = /[^a-z0-9]+/;
    return !regex.test((this as any).role.name);
  }
  private get roleNameLength(): boolean {
    return (this as any).role.name.length <= 1;
  }
  private get isFormValid() {
    return (this as any).$v.$invalid;
  }

  /*----------  Methods  ----------*/
  handleCreateRole(): void {
    this.error = null;
    if (this.$v.$invalid) {
      this.error = {
        class: "danger",
        msg: this.$t("roles.roleMsg.error").toString()
      };
      return;
    }
    this.$bvModal.hide("newRole");
    this.addRoleToList(this.role).then((res: AxiosResponse) => {
      if (res.status == 201) {
        this.error = {
          class: "success",
          msg: this.$t("roles.roleMsg.success").toString()
        };
        this.role = {
          name: ""
        };
        this.getAllRole();
      } else {
        this.error = {
          class: "danger",
          msg: res as any
        };
      }
    });
  }

  deleteUserRole(id: string | undefined): void {
    this.error = null;
    this.confirm((this as any).$t("roles.deleteConfirmation.content"), async () => {
      let res = await this.deleteRole(id);

      if (res.status === 204) {
        this.error = {
          class: "success",
          msg: this.$t("roles.roleMsg.deleteSuccess").toString()
        };
      } else {
        this.error = {
          class: "danger",
          msg: res
        };
      }
    });
  }

  checkDefaultRoles(userName: string): boolean {
    let canBeDelete: boolean;
    switch (userName) {
      case "administration":
        canBeDelete = false;
        break;
      case "project_leader":
        canBeDelete = false;
        break;
      case "project_manager":
        canBeDelete = false;
        break;
      case "construction_manager":
        canBeDelete = false;
        break;
      case "user":
        canBeDelete = false;
        break;
      case "agency":
        canBeDelete = false;
        break;
      default:
        canBeDelete = true;
        break;
    }
    return canBeDelete;
  }
  resetModal() {
    this.role.name = "";
    this.$bvModal.hide("newRole");
  }
}
